import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { cn } from '@/lib/utils';

import { useCheckoutStore, type PaymentPlan } from './check-out.hooks';

export function PlanSwitcherIsland({ className }: { className?: string }) {
  const checkoutStore = useCheckoutStore();

  if (Object.values(checkoutStore.planOptions).length === 1) return <></>;

  return (
    <Tabs
      className={className}
      defaultValue={checkoutStore.currentPlan.period}
      value={checkoutStore.currentPlan.period}
      onValueChange={(v) => {
        checkoutStore.switchPlan(
          checkoutStore.planOptions[
            v as keyof typeof checkoutStore.planOptions
          ],
        );
      }}
    >
      <TabsList className="h-auto rounded-full p-0">
        {Object.values(checkoutStore.planOptions).map((plan) => (
          <SubscriptionTab key={plan.priceId} plan={plan} />
        ))}
      </TabsList>
    </Tabs>
  );
}

function SubscriptionTab({ plan }: { plan: PaymentPlan }) {
  const checkoutFormStore = useCheckoutStore();
  const isActive = checkoutFormStore.currentPlan.period === plan.period;

  return (
    <TabsTrigger
      value={plan.period}
      className={cn(
        'flex w-36 flex-col items-center justify-center gap-1 rounded-full px-4 py-3 sm:w-60 md:px-4',
        isActive &&
          'w-auto min-w-44 data-[state=active]:bg-primary md:min-w-64',
      )}
    >
      <p className={cn('text-lg font-[500]', isActive && 'text-[#F6F6F6]')}>
        {plan.period === 'yearly' ? 'Yearly' : 'Monthly'}
      </p>
      <div className="w-full">
        <p
          className={cn(
            'truncate text-sm font-[300]',
            isActive && 'text-[#F6F6F6]',
          )}
        >
          {plan.period === 'yearly'
            ? `$${(plan.amount / 12).toFixed(2)}/mo ($${plan.amount.toString()}/yr)`
            : `$${plan.amount.toString()} / month`}
        </p>
      </div>
    </TabsTrigger>
  );
}
